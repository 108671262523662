import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, } from '@ionic/vue';
import { arrowBackOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonButton,
        IonIcon,
        IonTitle,
    },
    setup() {
        const router = useRouter();
        return {
            arrowBackOutline,
            counter: 0,
            handleBack: router.back,
            licenses: PLACEHOLDER_LICENSES,
        };
    }
});
